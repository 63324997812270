import Script from 'next/script';

export const OneClickUpgradeButton = ({
  label,
  mountId,
  onLoad,
  bgColor,
  hoverBgColor,
}: {
  label: string;
  mountId?: string;
  onLoad?: VoidFunction;
  bgColor?: string;
  hoverBgColor?: string;
}) => {
  return (
    <>
      <Script id={`${mountId}-script`} strategy="afterInteractive">
        {`
       (function (w, d, s, o, f, js, fjs) {
         w.CF = w.CF || {};
         w.CF[o] =
           w.CF[o] ||
           function () {
             (w.CF[o].q = w.CF[o].q || []).push(arguments);
           };
         (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
         js.id = o;
         js.src = f;
         js.async = 1;
         fjs.parentNode.insertBefore(js, fjs);
       })(window, document, 'script', '${mountId}_instance', '${
          process.env.NEXT_PUBLIC_VERCEL_WIDGETS_URL
        }/one-click-upgrade-button/index.js?version=16');
       CF.${mountId}_instance('init', {
        enableExperimentLogic: true,
        label: '${label}',
        ${mountId ? `mountId: '${mountId}',` : ''}
        ${mountId ? `element: document.getElementById('${mountId}'),` : ''}
        ${onLoad ? `onLoad: ${onLoad.toString()},` : ''}
        ${bgColor ? `bgColor: '${bgColor}',` : ''}
        ${hoverBgColor ? `hoverBgColor: '${hoverBgColor}',` : ''}
       });
      `}
      </Script>
    </>
  );
};
