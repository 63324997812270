import dynamic from 'next/dynamic';
import { useState } from 'react';

import { useFormatMessage } from 'utilities/i18n';
import { useSiteDataFetcher } from 'api/requests/site/site';
import { AllAccessYearlyBanner, AllAccessYearlyBannerExtend, BannerType } from 'api/requests/site/site.types';
import { undoSubscriptionCancellationFetcher } from 'api/requests/subscriptions/subscriptions';
import { useRequestErrorHandler } from 'hooks/useRequestErrorHandler/useRequestErrorHandler';

import { SiteWideBannerProps } from './SiteWideBanner.types';
import { SiteWideBannerAllAccessYearlyProps } from './SiteWideBannerAllAccessYearly.types';
import { SiteWideBannerAllAccessYearlyExtendProps } from './SiteWideBannerAllAccessYearlyExtend.types';

const SiteWideBannerComponent = dynamic<SiteWideBannerProps>(() =>
  import('./SiteWideBanner').then(module => module.SiteWideBanner),
);

const SiteWideBannerAllAccessYearlyComponent = dynamic<SiteWideBannerAllAccessYearlyProps>(() =>
  import('./SiteWideBannerAllAccessYearly').then(module => module.SiteWideBannerAllAccessYearly),
);

const SiteWideBannerAllAccessYearlyExtendComponent = dynamic<SiteWideBannerAllAccessYearlyExtendProps>(() =>
  import('./SiteWideBannerAllAccessYearlyExtend').then(module => module.SiteWideBannerAllAccessYearlyExtend),
);

const HIDE_BANNER_TYPES = new Set([BannerType.SPARK_FREE_TRIAL]);

export function SiteWideBanner() {
  const t = useFormatMessage();
  const { data, refetch } = useSiteDataFetcher();
  const [showUndoCancelBanner, setShowUndoCancelBanner] = useState(false);
  const onError = useRequestErrorHandler({ hasSnackbar: true });
  const banner = data?.banner;

  if (!banner) {
    return null;
  }

  if (HIDE_BANNER_TYPES.has(banner.type)) {
    return null;
  }

  const restoreSubscription = async () => {
    if (banner.type === BannerType.ALL_ACCESS_YEARLY || banner.type === BannerType.ALL_ACCESS_YEARLY_EXTEND) {
      return;
    }

    const id = banner?.data?.subscriptionId;

    if (id) {
      await undoSubscriptionCancellationFetcher({ id, onError });
      refetch();
      setShowUndoCancelBanner(true);
    }
  };

  const bannerOnClickMap: {
    [key in BannerType]?: VoidFunction;
  } = {
    [BannerType.FREE_UNDO_CANCELLATION]: restoreSubscription,
  };

  const buttonOnClick = bannerOnClickMap[banner.type];

  if (showUndoCancelBanner) {
    return (
      <div className="h-[41px]">
        <p className="w-full bg-secondary-500 text-center text-[14px] font-bold uppercase tracking-[0.9px] text-service-white">
          {t('component.undo_cancellation_snackbar')}
        </p>
      </div>
    );
  }

  if (
    banner.type !== BannerType.ALL_ACCESS_YEARLY &&
    banner.type !== BannerType.ALL_ACCESS_YEARLY_EXTEND &&
    banner.type !== BannerType.ALL_ACCESS_LIMITED
  ) {
    return <SiteWideBannerComponent {...banner} onCountdownIsOver={refetch} buttonOnClick={buttonOnClick} />;
  }

  if (banner.type === BannerType.ALL_ACCESS_YEARLY || banner.type === BannerType.ALL_ACCESS_LIMITED) {
    return (
      <SiteWideBannerAllAccessYearlyComponent {...(banner as AllAccessYearlyBanner)} takeover={data?.promo?.takeover} />
    );
  }

  if (banner.type === BannerType.ALL_ACCESS_YEARLY_EXTEND) {
    return (
      <SiteWideBannerAllAccessYearlyExtendComponent
        {...(banner as AllAccessYearlyBannerExtend)}
        takeover={data?.promo?.takeover}
      />
    );
  }

  return null;
}
