import * as React from 'react'
import { addOrReplaceLanguageInUrl, getLanguageSegment, validateLocale } from 'cf-i18n/helpers'
import { getLangCookie, setLangCookie } from 'cf-i18n/cookies'

import { useUserLanguage } from '../contexts/auth'

export function useUserLanguageRedirect() {
  const userLanguage = useUserLanguage()

  React.useEffect(() => {
    const currentPageUrl = window.location.href
    const cookieLanguage = getLangCookie()
    const appLanguage = getLanguageSegment(window.location.href)

    if (
      (userLanguage && !validateLocale(userLanguage)) ||
      (cookieLanguage && !validateLocale(cookieLanguage))
    ) {
      // If the cookie has an invalid language abort redirection
      // If wordpress fails to replace in the template the correct language abort redirection
      return
    }

    if (userLanguage && userLanguage !== appLanguage) {
      const newLocation = addOrReplaceLanguageInUrl(currentPageUrl, userLanguage)
      currentPageUrl.includes('staging') &&
        // eslint-disable-next-line no-console
        console.log(
          `🟡 Redirect to: ${{
            to: newLocation,
            from: currentPageUrl,
            trigger: 'use-user-language-redirect',
          }}`,
        )

      if (userLanguage !== cookieLanguage) {
        // The cookie is updated when switching languages; however if a user logs in we need to update the cookie
        // otherwise the page will reload and trigger this again causing an infinite loop
        setLangCookie(userLanguage)
      }
      window.location.href = newLocation
    }
  }, [userLanguage])
}
