import classNames from 'classnames';
import { CheckIcon } from 'primitives/icons';

import { useFormatMessage } from 'utilities/i18n';
import { sendAmplitudeLog } from 'components/app/analytics/amplitude/Amplitude.utils';
import { AmplitudeEvent } from 'components/app/analytics/amplitude/Amplitude.types';
import { BannerType } from 'api/requests/site/site.types';
import { useSiteDataFetcher } from 'api/requests/site/site';
import { AppRoute } from 'components/app/App.types';

import { OneClickUpgradeButton } from './OneClickUpgradeButton';
import { SiteWideBannerAllAccessYearlyProps } from './SiteWideBannerAllAccessYearly.types';

export function SiteWideBannerAllAccessYearly(props: SiteWideBannerAllAccessYearlyProps) {
  const { headline, buttonText, buttonUrl, features, banner_above_nav, banner_above_nav_state, type, takeover } = props;
  const t = useFormatMessage();
  const { data } = useSiteDataFetcher();

  function handleButtonClick() {
    sendAmplitudeLog(AmplitudeEvent.CLICKED_BANNER_ABOVE_NAV, {
      banner_above_nav,
      banner_above_nav_state,
    });
  }

  const enableStripeBilling = Boolean(data?.billing);

  let backgroundImage = props.backgroundImage;
  let textColor = '';
  let buttonColor = '';
  let buttonTextColor = '';

  if (takeover?.is_active) {
    ({
      above_nav_banner_img: backgroundImage,
      above_nav_banner_color: textColor,
      above_nav_banner_btn_color: buttonColor,
      above_nav_banner_btn_text_color: buttonTextColor,
    } = takeover);
  }

  return (
    <div
      className="flex justify-center bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div
        className={classNames(
          type === BannerType.ALL_ACCESS_LIMITED && 'text-white',
          'relative z-[1101] flex flex-1 flex-col justify-between gap-5 p-[10px] px-6 md:flex-row xl:max-w-7xl 2xl:max-w-[1536px]',
        )}
      >
        <div>
          <div
            className="mb-[10px] text-base md:text-lg [&_.u-bold]:font-bold md:[&_.u-bold]:text-2xl"
            dangerouslySetInnerHTML={{ __html: headline }}
            style={{ color: textColor }}
          ></div>
          {type !== BannerType.ALL_ACCESS_LIMITED && enableStripeBilling ? (
            <span
              className="flex w-full md:hidden"
              id="oneClickUpgradeButtonMountContainer"
              onClick={handleButtonClick}
            >
              <span
                className="flex h-[45px] w-full items-center justify-center rounded bg-primary-500 py-5 text-base font-bold uppercase text-white hover:bg-primary-700"
                style={{
                  backgroundColor: buttonColor,
                  color: buttonTextColor,
                }}
              >
                {buttonText}
              </span>
              <OneClickUpgradeButton
                bgColor={takeover?.is_active ? buttonColor : undefined}
                hoverBgColor={takeover?.is_active ? buttonColor : undefined}
                label={buttonText}
                mountId="oneClickUpgradeButtonMountContainer"
              />
            </span>
          ) : (
            <a
              href={
                Boolean(data?.billing)
                  ? data?.promo?.yearly_promo
                    ? AppRoute.B_YEARLY_PROMO
                    : AppRoute.B_YEARLY
                  : buttonUrl
              }
              onClick={handleButtonClick}
              className={classNames(
                type === BannerType.ALL_ACCESS_LIMITED
                  ? 'bg-[linear-gradient(to_bottom,#8beb6a,#1d9166)]'
                  : 'bg-primary-500 hover:bg-primary-700',
                'flex h-[45px] w-full items-center justify-center rounded  py-5 text-base font-bold uppercase text-white md:hidden',
              )}
              style={{
                backgroundColor: buttonColor,
                color: buttonTextColor,
              }}
            >
              {buttonText}
            </a>
          )}

          {features ? (
            <ul className="mt-4">
              {features.map((feat, index) => {
                return (
                  <li
                    key={index}
                    style={{ color: textColor }}
                    className={classNames(index > 0 && 'hidden md:flex', 'my-1 flex')}
                  >
                    <CheckIcon className="mr-1 text-green-500" style={{ color: textColor }} />
                    <span dangerouslySetInnerHTML={{ __html: feat }} />
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>
        <div className="mt-6 hidden w-[29%] flex-col items-center md:flex">
          {type !== BannerType.ALL_ACCESS_LIMITED && enableStripeBilling ? (
            <span
              className="hidden w-full md:flex"
              id="oneClickUpgradeButtonMountContainerMd"
              onClick={handleButtonClick}
            >
              <span
                className="flex h-[45px] w-full items-center justify-center rounded bg-primary-500 py-5 text-base font-bold uppercase text-white hover:bg-primary-700"
                style={{
                  backgroundColor: buttonColor,
                  color: buttonTextColor,
                }}
              >
                {buttonText}
              </span>
              <OneClickUpgradeButton
                bgColor={takeover?.is_active ? buttonColor : undefined}
                hoverBgColor={takeover?.is_active ? buttonColor : undefined}
                label={buttonText}
                mountId="oneClickUpgradeButtonMountContainerMd"
              />
            </span>
          ) : (
            <a
              href={Boolean(data?.billing) ? AppRoute.B_YEARLY_PROMO : buttonUrl}
              onClick={handleButtonClick}
              className={classNames(
                type === BannerType.ALL_ACCESS_LIMITED
                  ? 'bg-[linear-gradient(to_bottom,#8beb6a,#1d9166)]'
                  : 'bg-primary-500 hover:bg-primary-700',
                'hidden h-[45px] w-full items-center justify-center rounded  py-5 text-base font-bold uppercase text-white md:flex',
              )}
              style={{
                backgroundColor: buttonColor,
                color: buttonTextColor,
              }}
            >
              {buttonText}
            </a>
          )}
          <p className="mt-[10px] text-xs" style={{ color: textColor }}>
            {t('banners.cancel_anytime')}
          </p>
        </div>
      </div>
    </div>
  );
}
