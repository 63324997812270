import React, { useState, useEffect } from 'react';

import { Accordion as AccordionComponent } from 'components/elements/molecules/accordion/Accordion';

import { AccordionContainerProps } from './Accordion.types';

export function Accordion(props: AccordionContainerProps) {
  const { header, children, isExpanded } = props;
  const [expanded, setExpanded] = useState<boolean>(isExpanded !== undefined ? isExpanded : true);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < 768) {
      setTimeout(() => setExpanded(false), 200);
    }
  }, []);

  return (
    <div className="flex w-full flex-col">
      <AccordionComponent.Trigger
        onClick={() => setExpanded(prevExpanded => !prevExpanded)}
        expanded={expanded}
        header={header}
      />
      <AccordionComponent expanded={expanded}>{children}</AccordionComponent>
    </div>
  );
}
