import type { Language } from 'codegen/types'
import * as React from 'react'
import { useCookies } from 'react-cookie'
import { getCookie, setCookie } from 'utils/dom'

import { validateLocale } from './helpers'

export const LANGUAGE_COOKIE_NAME = 'lang'

const env = process.env.NEXT_PUBLIC_ENV
const isPreview = env !== 'staging' && env !== 'production'
const domain = isPreview ? undefined : '.creativefabrica.com'
const path = '/'

export function getLangCookie() {
  const lang = getCookie(LANGUAGE_COOKIE_NAME)

  if (validateLocale(lang)) {
    return lang
  }

  return null
}

export function setLangCookie(lang: Language) {
  setCookie({
    name: LANGUAGE_COOKIE_NAME,
    value: lang,
    domain,
    path,
  })
}

export function useLangCookie() {
  const [{ [LANGUAGE_COOKIE_NAME]: lang }, set, remove] = useCookies([LANGUAGE_COOKIE_NAME])

  return [
    validateLocale(lang) ? lang : null,
    React.useCallback(
      (lang: Language) =>
        set(LANGUAGE_COOKIE_NAME, lang, {
          domain,
          path,
        }),
      [set],
    ),
    React.useCallback(
      () =>
        remove(LANGUAGE_COOKIE_NAME, {
          domain,
          path,
        }),
      [remove],
    ),
  ] as const
}
